// import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import Signin from "./Signin/Signin";
import LoadingPage from "./Signin/LoadingPage";

function App() {
  // const TRACKING_ID = "G-Y37N4DHC9G";
  // ReactGA.initialize(TRACKING_ID);
  // ReactGA.send({ hitType: "pageview", page: "/" });
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingPage, setLoadingPage] = useState(true);
  const [logInUser, setLogInUser] = useState("");

  function getTokens() {
    fetch(`${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/token`, {
      method: "POST",
      body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&code=${code}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.refresh_token) {
          validateToken(data.id_token);
          localStorage.setItem("ItnTrialsRefreshToken", data.refresh_token);
          localStorage.setItem("ItnTrialsAccessToken", data.access_token);
          localStorage.setItem("ItnTrialsIdToken", data.id_token);
        }
      })
      .catch((error) => {
        setIsSignedIn(false);
        setLoadingPage(false);
        setErrorMessage("Please try to sign in again.");
        console.log(error);
      });
  }

  function validateToken(token) {
    fetch(
      "https://9e3e5s13h7.execute-api.us-west-2.amazonaws.com/production/transactions",
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data["email"]) {
          setLogInUser(data["email"].split("@")[0]);
          setIsSignedIn(true);
          setLoadingPage(false);
        } else if (!data["email"] && code) {
          getTokens();
        } else {
          throw new Error("Please try to sign in again.");
        }
      })
      .catch((error) => {
        setIsSignedIn(false);
        setLoadingPage(false);
        setErrorMessage("Please try to sign in again.");
        console.log(error);
      });
  }

  useEffect(() => {
    if (code && !localStorage.getItem("ItnTrialsIdToken")) {
      getTokens();
    } else if (code && localStorage.getItem("ItnTrialsIdToken")) {
      validateToken(localStorage.getItem("ItnTrialsIdToken"));
    } else if (!code && localStorage.getItem("ItnTrialsIdToken")) {
      validateToken(localStorage.getItem("ItnTrialsIdToken"));
    } else if (!code && !localStorage.getItem("ItnTrialsIdToken")) {
      setIsSignedIn(false);
      setLoadingPage(false);
    }
  }, [code]);

  return (
    <>
      {loadingPage ? (
        <LoadingPage />
      ) : isSignedIn ? (
        <>
          <Header setIsSignedIn={setIsSignedIn} logInUser={logInUser} />
          <Main />
          <Footer />
        </>
      ) : (
        <Signin
          setIsSignedIn={setIsSignedIn}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          validateToken={validateToken}
          setLogInUser={setLogInUser}
        />
      )}
    </>
  );
}

export default App;
