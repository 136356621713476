function Footer() {
  return (
    <footer className="itn-footer">
      <div className="itn-footer-content">
        <p className="itn-footer-text">
          Clinical studies are being conducted by the Immune Tolerance Network
          and sponsored by the National Institute of Allergy and Infectious
          Diseases (NIH funded).
        </p>
        <a href="https://www.nih.gov/" target="_blank" rel="noreferrer">
          <img className="itn-footer-logo" src="/nih-logo.png" alt="NIH logo" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
