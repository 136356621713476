import { useState, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Menu from "./Menu";
import ScrollTop from "./ScrollTop";
import studies from "./studies.json";
import sites from "./sites.json";
import prescreendata from "./prescreendata.json";

function Main({ email }) {
  const [menu, setMenu] = useState("trial");
  const [filterButtons, setFilterButtons] = useState({
    studyStatusOpen: false,
    studyStatusDevelopment: false,
    studyStatusClosed: false,
    websiteStatusOnline: false,
    websiteStatusOffline: false,
    deployedByDct: false,
    deployedByBriit: false,
    prescreenFormYes: false,
    prescreenFormNo: false,
    isMultilingualYes: false,
    isMultilingualNo: false,
    cmsLinkYes: false,
    cmsLinkNo: false,
  });
  const [resultsStudies, setResultsStudies] = useState(
    studies.sort((a, b) => a.studyName.localeCompare(b.studyName))
  );

  const [filters, setFilters] = useState({});
  const [query, setQuery] = useState("");
  const [siteData, setSiteData] = useState([]);
  const [ITNSiteData, setITNSiteData] = useState(sites.sort(comparePlacement));

  function filterStatus(buttonName) {
    return filterButtons[buttonName] ? "active-filter" : "";
  }

  function filterToggle(buttonName) {
    setQuery("");
    setFilterButtons((prev) => {
      const newFilterButtons = { ...prev };
      newFilterButtons[buttonName] = !prev[buttonName];
      const keyNames = buttonName.split(/(?=[A-Z])/);

      if (newFilterButtons[buttonName]) {
        setFilters((prev) => {
          const newFilters = { ...prev };
          newFilters[keyNames[0] + keyNames[1]] = keyNames[2];
          return newFilters;
        });
      } else {
        setFilters((prev) => {
          const newFilters = { ...prev };
          delete newFilters[keyNames[0] + keyNames[1]];
          return newFilters;
        });
      }
      return newFilterButtons;
    });
  }

  function statusStyle(status) {
    switch (status) {
      case "open":
        return "itn-main-study-status-open";
      case "online":
        return "itn-main-study-status-online";
      case "closed":
        return "itn-main-study-status-closed";
      case "offline":
        return "itn-main-study-status-offline";
      case "development":
        return "itn-main-study-status-development";
      default:
        return;
    }
  }

  function filterClicked() {
    for (const button in filterButtons) {
      if (filterButtons[button]) {
        return true;
      }
    }
    return false;
  }

  function filterClear() {
    setFilterButtons({
      studyStatusOpen: false,
      studyStatusDevelopment: false,
      studyStatusClosed: false,
      websiteStatusOnline: false,
      websiteStatusOffline: false,
      deployedByDct: false,
      deployedByBriit: false,
      prescreenFormYes: false,
      prescreenFormNo: false,
      isMultilingualYes: false,
      isMultilingualNo: false,
      cmsLinkYes: false,
      cmsLinkNo: false,
    });
    setFilters({});
  }

  function getStudyStatus(siteName) {
    const siteDataEntry = siteData.filter((site) => site.siteName === siteName);
    return siteDataEntry[0]?.studyStatus;
  }

  function getWebsiteStatus(siteName) {
    const siteDataEntry = siteData.filter((site) => site.siteName === siteName);
    return siteDataEntry[0]?.websiteStatus;
  }

  function getLastCheck(siteName) {
    const siteDataEntry = siteData.find((site) => site.siteName === siteName);
    const lastCheckData = siteDataEntry?.lastCheck;

    if (lastCheckData) {
      if (lastCheckData === "1970-01-01T00:00:00Z") {
        return "N/A";
      } else {
        // Format to local date-time and add timezone
        let checkDate = new Date(lastCheckData);
        let formattedDate = checkDate.toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        });
        let formattedTime = checkDate.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
          timeZoneName: "short",
        });

        const displayCheckDate = `${formattedDate} ${formattedTime}`;
        return displayCheckDate;
      }
    } else {
      return "N/A";
    }
  }

  function checkTimeRed(siteName) {
    const siteDataEntry = siteData.find((site) => site.siteName === siteName);
    const lastCheckData = siteDataEntry?.lastCheck;
    const currentTime = new Date().getTime();
    const checkTime = new Date(lastCheckData).getTime();
    const timeDifference = currentTime - checkTime;
    if (timeDifference > 14400000) {
      return true;
    } else {
      return false;
    }
  }

  function getLastOutage(siteName) {
    const siteDataEntry = siteData.filter((site) => site.siteName === siteName);
    const lastOutageData = siteDataEntry[0]?.lastOutage;

    if (lastOutageData) {
      if (lastOutageData === "1970-01-01T00:00:00Z") {
        return "N/A";
      } else {
        // Format to local date-time and add timezone
        let outageDate = new Date(lastOutageData);
        let formattedDate = outageDate.toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        });
        let formattedTime = outageDate.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
          timeZoneName: "short",
        });

        const displayOutageDate = `${formattedDate} ${formattedTime}`;
        return displayOutageDate;
      }
    } else {
      return "N/A";
    }
  }

  function outageTimeRed(siteName) {
    const siteDataEntry = siteData.filter((site) => site.siteName === siteName);
    const lastOutageData = siteDataEntry[0]?.lastOutage;
    const currentTime = new Date().getTime();
    const outageTime = new Date(lastOutageData).getTime();
    const timeDifference = currentTime - outageTime;
    if (timeDifference < 14400000) {
      return true;
    } else {
      return false;
    }
  }

  function getContact(siteName) {
    const siteDataEntry = siteData.filter((site) => site.siteName === siteName);
    return siteDataEntry[0]?.contactsSent;
  }

  function getPrescreener(siteName) {
    const siteDataEntry = siteData.filter((site) => site.siteName === siteName);
    return siteDataEntry[0]?.prescreenersSubmitted;
  }

  function comparePlacement(a, b) {
    return a.placement - b.placement;
  }

  useEffect(() => {
    const requestData = async () => {
      try {
        const res = await axios.get("https://api.itntrials.org/site-metrics");
        setSiteData(res.data.siteMetrics);
      } catch (err) {
        alert(`Unable to retrieve data.\n Error: ${err}`);
      }
    };
    requestData();
  }, []);

  useEffect(() => {
    function qualifiedStudy(studyObj) {
      for (const key in filters) {
        if (key === "studyStatus") {
          console.log(key, filters[key]);
          if (
            getStudyStatus(studyObj.studyName) !== filters[key].toLowerCase()
          ) {
            return false;
          }
        } else if (key === "websiteStatus") {
          console.log(key, filters[key]);
          if (
            getWebsiteStatus(studyObj.studyName) !== filters[key].toLowerCase()
          ) {
            return false;
          }
        } else {
          if (studyObj[key] !== filters[key]) {
            return false;
          }
        }
      }
      return true;
    }

    setResultsStudies((prev) =>
      studies
        .filter((study) => qualifiedStudy(study))
        .sort((a, b) => a.studyName.localeCompare(b.studyName))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const trialSites = (
    <div className="itn-main-content">
      <header className="itn-main-header">
        <div className="itn-main-title-section">
          <h1 className="itn-main-title">Clinical Trial Recruiting Sites</h1>
        </div>
        <div className="itn-main-filter-section">
          <label className="itn-main-filter-title">Filter by</label>
          {/* <button
            className={`itn-main-filter-button ${filterStatus(
              "studyStatusOpen"
            )} ${
              filterButtons.studyStatusDevelopment ||
              filterButtons.studyStatusClosed
                ? "disabled-filter"
                : ""
            }`}
            onClick={() => {
              filterToggle("studyStatusOpen");
            }}
            disabled={
              filterButtons.studyStatusDevelopment ||
              filterButtons.studyStatusClosed
            }
          >
            {`Study Status: Open (${
              siteData.filter((site) => site.studyStatus === "open").length
            })`}
          </button>
          <button
            className={`itn-main-filter-button ${filterStatus(
              "studyStatusDevelopment"
            )} ${
              filterButtons.studyStatusOpen || filterButtons.studyStatusClosed
                ? "disabled-filter"
                : ""
            }`}
            onClick={() => {
              filterToggle("studyStatusDevelopment");
            }}
            disabled={
              filterButtons.studyStatusOpen || filterButtons.studyStatusClosed
            }
          >
            {`Study Status: Development (${
              siteData.filter((site) => site.studyStatus === "development")
                .length
            })`}
          </button>
          <button
            className={`itn-main-filter-button ${filterStatus(
              "studyStatusClosed"
            )} ${
              filterButtons.studyStatusOpen ||
              filterButtons.studyStatusDevelopment
                ? "disabled-filter"
                : ""
            }`}
            onClick={() => {
              filterToggle("studyStatusClosed");
            }}
            disabled={
              filterButtons.studyStatusOpen ||
              filterButtons.studyStatusDevelopment
            }
          >
            {`Study Status: Closed (${
              siteData.filter((site) => site.studyStatus === "closed").length
            })`}
          </button> */}
          <button
            className={`itn-main-filter-button ${filterStatus(
              "websiteStatusOnline"
            )} ${filterButtons.websiteStatusOffline ? "disabled-filter" : ""}`}
            onClick={() => {
              filterToggle("websiteStatusOnline");
            }}
            disabled={filterButtons.websiteStatusOffline}
          >
            {`Website Status: Online (${
              siteData.filter((site) => site.websiteStatus === "online")
                .length - 5
            })`}
          </button>
          <button
            className={`itn-main-filter-button ${filterStatus(
              "websiteStatusOffline"
            )} ${filterButtons.websiteStatusOnline ? "disabled-filter" : ""}`}
            onClick={() => {
              filterToggle("websiteStatusOffline");
            }}
            disabled={filterButtons.websiteStatusOnline}
          >
            {`Website Status: Offline (${
              siteData.filter((site) => site.websiteStatus === "offline")
                .length - 1
            })`}
          </button>
          <button
            className={`itn-main-filter-button ${filterStatus(
              "deployedByDct"
            )} ${filterButtons.deployedByBriit ? "disabled-filter" : ""}`}
            onClick={() => {
              filterToggle("deployedByDct");
            }}
            disabled={filterButtons.deployedByBriit}
          >
            {`Deployed By: DCT (${
              studies.filter((study) => study.deployedBy === "Dct").length
            })`}
          </button>
          <button
            className={`itn-main-filter-button ${filterStatus(
              "deployedByBriit"
            )} ${filterButtons.deployedByDct ? "disabled-filter" : ""}`}
            onClick={() => {
              filterToggle("deployedByBriit");
            }}
            disabled={filterButtons.deployedByDct}
          >
            {`Deployed By: BRI IT (${
              studies.filter((study) => study.deployedBy === "Briit").length
            })`}
          </button>
          <button
            className={`itn-main-filter-button ${filterStatus("cmsLinkYes")} ${
              filterButtons.cmsLinkNo ? "disabled-filter" : ""
            }`}
            onClick={() => {
              filterToggle("cmsLinkYes");
            }}
            disabled={filterButtons.cmsLinkNo}
          >
            {`CMS: Yes (${
              studies.filter((study) => study.cmsLink === "Yes").length
            })`}
          </button>
          <button
            className={`itn-main-filter-button ${filterStatus("cmsLinkNo")} ${
              filterButtons.cmsLinkYes ? "disabled-filter" : ""
            }`}
            onClick={() => {
              filterToggle("cmsLinkNo");
            }}
            disabled={filterButtons.cmsLinkYes}
          >
            {`CMS: No (${
              studies.filter((study) => study.cmsLink === "No").length
            })`}
          </button>
          <button
            className={`itn-main-filter-button ${filterStatus(
              "prescreenFormYes"
            )} ${filterButtons.prescreenFormNo ? "disabled-filter" : ""}`}
            onClick={() => {
              filterToggle("prescreenFormYes");
            }}
            disabled={filterButtons.prescreenFormNo}
          >
            {`Pre-screener Form: Yes (${
              studies.filter((study) => study.prescreenForm === "Yes").length
            })`}
          </button>
          <button
            className={`itn-main-filter-button ${filterStatus(
              "prescreenFormNo"
            )} ${filterButtons.prescreenFormYes ? "disabled-filter" : ""}`}
            onClick={() => {
              filterToggle("prescreenFormNo");
            }}
            disabled={filterButtons.prescreenFormYes}
          >
            {`Pre-screener Form: No (${
              studies.filter((study) => study.prescreenForm === "No").length
            })`}
          </button>
          <button
            className={`itn-main-filter-button ${filterStatus(
              "isMultilingualYes"
            )} ${filterButtons.isMultilingualNo ? "disabled-filter" : ""}`}
            onClick={() => {
              filterToggle("isMultilingualYes");
            }}
            disabled={filterButtons.isMultilingualNo}
          >
            {`Multilingual: Yes (${
              studies.filter((study) => study.isMultilingual === "Yes").length
            })`}
          </button>
          <button
            className={`itn-main-filter-button ${filterStatus(
              "isMultilingualNo"
            )} ${filterButtons.isMultilingualYes ? "disabled-filter" : ""}`}
            onClick={() => {
              filterToggle("isMultilingualNo");
            }}
            disabled={filterButtons.isMultilingualYes}
          >
            {`Multilingual: No (${
              studies.filter((study) => study.isMultilingual === "No").length
            })`}
          </button>
          {filterClicked() && (
            <button
              className="itn-main-filter-button itn-main-filter-clear"
              onClick={filterClear}
            >
              <RxCross2 className="clear-icon" />
              Clear
            </button>
          )}
        </div>

        <div className="itn-main-search-section">
          <p className="itn-main-result-text">
            Results: {resultsStudies.length}
          </p>
          <div className="itn-main-search-section">
            <label className="itn-main-search-title">
              <AiOutlineSearch />
            </label>
            <input
              className="itn-main-search"
              type="text"
              placeholder="Search..."
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                setFilterButtons({
                  studyStatusOpen: false,
                  studyStatusDevelopment: false,
                  studyStatusClosed: false,
                  siteStatusActive: false,
                  siteStatusInactive: false,
                  deployedByDct: false,
                  deployedByBriit: false,
                  prescreenFormYes: false,
                  prescreenFormNo: false,
                  isMultilingualYes: false,
                  isMultilingualNo: false,
                  cmsLinkYes: false,
                  cmsLinkNo: false,
                });
                setResultsStudies(
                  studies.filter((study) =>
                    study.studyName.toLowerCase().includes(e.target.value)
                  )
                );
              }}
            />
            {query.length !== 0 && (
              <button
                className="itn-main-search-clear"
                onClick={() => {
                  setQuery("");
                  setResultsStudies(
                    studies.sort((a, b) =>
                      a.studyName.localeCompare(b.studyName)
                    )
                  );
                }}
              >
                <RxCross2 />
              </button>
            )}
          </div>
        </div>
      </header>

      <article className="itn-main-studies">
        {!resultsStudies.length ? (
          <h2 className="itn-study-not-found">No matching study found.</h2>
        ) : (
          resultsStudies.map((study, index) => (
            <div className="itn-main-study-card" key={index}>
              <h1 className="itn-main-study-title">{study.studyName}</h1>
              <div className="itn-main-study-header">
                <a href={study.linkUrl} target="_blank" rel="noreferrer">
                  <img
                    className="itn-main-study-logo"
                    src={study.studyLogo}
                    alt={`${study.studyName} logo`}
                  />
                </a>
                <p className="itn-main-study-description">
                  {study.studyDescription !== "N/A"
                    ? study.studyDescription
                    : ""}
                </p>
              </div>

              {study.studyNumber.length > 2 && (
                <div className="itn-main-study-status">
                  <h3 className="itn-main-study-status-title">Study Number</h3>
                  <p className="itn-main-study-status-content">
                    {study.studyNumber}
                  </p>
                </div>
              )}

              <hr className="itn-main-study-link-line" />

              {study.link !== "N/A" && (
                <div className="itn-main-study-links">
                  <a
                    className="itn-main-study-status-content itn-main-study-status-link itn-main-study-status-study-link"
                    href={study.linkUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {study.link}
                  </a>
                </div>
              )}

              {study.cmsLink === "Yes" && study.cms2Link === "Yes" && (
                <>
                  <div className="itn-main-study-links">
                    <a
                      className="itn-main-study-status-content itn-main-study-status-link"
                      href={study.stagingSite}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Staging
                    </a>
                    <a
                      className="itn-main-study-status-content itn-main-study-status-link"
                      href={study.cmsLinkUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      CMS (Staging)
                    </a>
                  </div>
                  <div className="itn-main-study-links">
                    <a
                      className="itn-main-study-status-content itn-main-study-status-link"
                      href={study.preapprovalSite}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Pre-approval
                    </a>
                    <a
                      className="itn-main-study-status-content itn-main-study-status-link"
                      href={study.cms2LinkUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      CMS (Pre-approval)
                    </a>
                  </div>
                </>
              )}

              {study.link !== "N/A" && (
                <hr className="itn-main-study-link-line" />
              )}

              {/* <div className="itn-main-study-status">
                <h3 className="itn-main-study-status-title">Study Status</h3>
                <p
                  className={`itn-main-study-status-content ${statusStyle(
                    getStudyStatus(study.studyName)
                  )}`}
                >
                  {getStudyStatus(study.studyName)}
                </p>
              </div> */}
              <div className="itn-main-study-status">
                <h3 className="itn-main-study-status-title">Website Status</h3>
                <p
                  className={`itn-main-study-status-content ${statusStyle(
                    getWebsiteStatus(study.studyName)
                  )}`}
                >
                  {getWebsiteStatus(study.studyName)}
                </p>
              </div>
              {getWebsiteStatus(study.studyName) === "online" && (
                <>
                  <div className="itn-main-study-status">
                    <h3 className="itn-main-study-status-title">
                      Last Checked
                    </h3>
                    <p
                      className={`itn-main-study-status-content itn-main-study-date ${
                        checkTimeRed(study.studyName)
                          ? "itn-main-study-date-red"
                          : ""
                      }`}
                    >
                      {getLastCheck(study.studyName)}
                    </p>
                  </div>

                  <div className="itn-main-study-status">
                    <h3 className="itn-main-study-status-title">Last Outage</h3>
                    <p
                      className={`itn-main-study-status-content itn-main-study-date ${
                        outageTimeRed(study.studyName)
                          ? "itn-main-study-date-red"
                          : ""
                      }`}
                    >
                      {getLastOutage(study.studyName)}
                    </p>
                  </div>
                </>
              )}

              <div className="itn-main-study-status">
                <h3 className="itn-main-study-status-title">Deployed By</h3>
                <p className="itn-main-study-status-content">
                  {study.deployedBy.length === 3 ? (
                    study.deployedBy === "Dct" ? (
                      <a
                        className="itn-main-study-status-content itn-main-study-status-link itn-main-study-develop-link"
                        href="mailto:dct@immunetolerance.org"
                        target="_blank"
                        rel="noreferrer"
                      >
                        DCT
                      </a>
                    ) : (
                      "N/A"
                    )
                  ) : (
                    <a
                      className="itn-main-study-status-content itn-main-study-status-link itn-main-study-develop-link"
                      href="mailto:helpdesk@immunetolerance.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      BRI IT
                    </a>
                  )}
                </p>
              </div>

              <hr className="itn-main-study-link-line" />

              <div className="itn-main-study-status">
                <h3 className="itn-main-study-status-title">Multilingual</h3>
                <p className="itn-main-study-status-content">
                  {study.isMultilingual}
                </p>
              </div>

              <div className="itn-main-study-status">
                <h3 className="itn-main-study-status-title">
                  Pre-screener Form
                </h3>
                <p className="itn-main-study-status-content">
                  {study.prescreenForm}
                </p>
              </div>

              {study.prescreenForm === "Yes" && (
                <div className="itn-main-study-status">
                  <h3 className="itn-main-study-status-title">
                    Subjects Pre-screened
                  </h3>
                  <p
                    className="itn-main-study-status-content"
                    data-tooltip-id="prescreen-data"
                  >
                    {getPrescreener(study.studyName)}
                    <span className="asterisk">*</span>
                  </p>
                </div>
              )}

              {study.prescreenForm === "Yes" && (
                <div className="itn-main-study-status">
                  <h3 className="itn-main-study-status-title">Contacts Sent</h3>
                  <p
                    className="itn-main-study-status-content"
                    data-tooltip-id="prescreen-data"
                  >
                    {getContact(study.studyName)}
                    <span className="asterisk">*</span>
                  </p>
                </div>
              )}

              <ReactTooltip
                id="prescreen-data"
                place="right"
                variant="dark"
                content={`* since ${prescreendata[0].date}`}
                style={{ fontStyle: "italic", fontSize: "0.75em" }}
              />
            </div>
          ))
        )}
      </article>

      <div className="side-note">
        <span className="asterisk">*</span> since {prescreendata[0].date}
      </div>
    </div>
  );

  const ITNSites = (
    <div className="itn-main-content">
      <header className="itn-main-header">
        <div className="itn-main-title-section">
          <h1 className="itn-main-title">ITN Sites</h1>
        </div>
      </header>

      <article className="itn-main-sites">
        {ITNSiteData.map((site) => (
          <div className="itn-main-site-card" key={site.placement}>
            <h1 className="itn-main-site-title">{site.siteName}</h1>
            <div className="itn-main-site-header">
              <a href={site.linkUrl} target="_blank" rel="noreferrer">
                <img
                  className="itn-main-site-logo"
                  src={site.siteLogo}
                  alt={`${site.siteName} logo`}
                />
              </a>
            </div>
            <hr className="itn-main-site-line" />

            <div className="itn-main-site-status">
              <h3 className="itn-main-site-status-title">Website Status</h3>
              <p
                className={`itn-main-site-status-content ${statusStyle(
                  getWebsiteStatus(site.siteName)
                )}`}
              >
                {getWebsiteStatus(site.siteName)}
              </p>
            </div>

            <div className="itn-main-site-status">
              <h3 className="itn-main-site-status-title">Last Checked</h3>
              <p
                className={`itn-main-site-status-content ${
                  checkTimeRed(site.siteName) ? "itn-main-site-date-red" : ""
                }`}
              >
                {getLastCheck(site.siteName)}
              </p>
            </div>

            <div className="itn-main-site-status">
              <h3 className="itn-main-site-status-title">Last Outage</h3>
              <p
                className={`itn-main-site-status-content ${
                  outageTimeRed(site.siteName) ? "itn-main-site-date-red" : ""
                }`}
              >
                {getLastOutage(site.siteName)}
              </p>
            </div>

            <div className="itn-main-site-status">
              <h3 className="itn-main-site-status-title">Deployed By</h3>
              <p className="itn-main-site-status-content">
                {site.deployedBy === "Dct" ? (
                  <a
                    className="itn-main-site-status-content itn-main-site-status-link itn-main-site-develop-link"
                    href="mailto:dct@immunetolerance.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    DCT
                  </a>
                ) : (
                  <a
                    className="itn-main-site-status-content itn-main-site-status-link itn-main-site-develop-link"
                    href="mailto:helpdesk@immunetolerance.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    BRI IT
                  </a>
                )}
              </p>
            </div>

            <div className="itn-main-site-status">
              <h3 className="itn-main-site-status-title">Link</h3>
              <a
                className="itn-main-site-status-content itn-main-site-status-link"
                href={site.linkUrl}
                target="_blank"
                rel="noreferrer"
              >
                {site.link}
              </a>
            </div>
          </div>
        ))}
      </article>
    </div>
  );

  return (
    <main className="itn-main">
      <Menu menu={menu} setMenu={setMenu} />
      {menu === "trial" ? trialSites : ITNSites}
      <ScrollTop />
    </main>
  );
}

export default Main;
