import { useState, useEffect } from "react";
import { IoIosArrowUp } from "react-icons/io";

function ScrollTop() {
  const [nearTop, setNearTop] = useState(false);

  function scrollToTop() {
    document.body.scrollTop = 145;
    document.documentElement.scrollTop = 145;
  }

  useEffect(() => {
    window.onscroll = function () {
      if (
        document.body.scrollTop > 250 ||
        document.documentElement.scrollTop > 250
      ) {
        setNearTop(true);
      } else {
        setNearTop(false);
      }
    };
  }, []);

  return (
    <div
      className={`itn-scroll-top ${nearTop ? "itn-scroll-top-show" : ""}`}
      onClick={scrollToTop}
    >
      <IoIosArrowUp />
    </div>
  );
}

export default ScrollTop;
