function Menu({menu, setMenu}) {

    return (
        <div className="itn-menu">
            <button className={`itn-menu-button itn-menu-trial ${menu === 'trial' ? "itn-menu-button-active" : ""}`} onClick={()=>setMenu("trial")}>Clinical Trial Recruiting Sites</button>
            <button className={`itn-menu-button itn-menu-sites ${menu === 'sites' ? "itn-menu-button-active" : ""}`} onClick={()=>setMenu("sites")}>ITN Sites</button>
        </div>
    )
}

export default Menu;