function Header({ setIsSignedIn, logInUser }) {
  function revokeTokens() {
    fetch(`${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/revoke`, {
      method: "POST",
      body: `client_id=${
        process.env.REACT_APP_COGNITO_CLIENT_ID
      }&token=${localStorage.getItem("ItnTrialsRefreshToken")}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("Tokens revoked.");
          localStorage.removeItem("ItnTrialsRefreshToken");
          localStorage.removeItem("ItnTrialsAccessToken");
          localStorage.removeItem("ItnTrialsIdToken");
          for (let key in localStorage) {
            if (key.substring(0, 31) === "CognitoIdentityServiceProvider.") {
              localStorage.removeItem(key);
            }
          }
          setIsSignedIn(false);
          document.location.assign("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <header className="itn-header">
      <div className="itn-header-content">
        <a
          href="https://www.immunetolerance.org/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="itn-header-logo" src="/itn-logo.png" alt="ITN logo" />
        </a>
        <div className="itn-header-text-section">
          <div className="itn-header-account-section">
            <p className="itn-header-account"><span>Username: </span>{logInUser}</p>
            <a className="itn-header-account-logout" onClick={revokeTokens}>
              Sign Out
            </a>
          </div>
          <a
            className="itn-cms-link"
            href="https://production-cms-landing-page.netlify.app/admin/"
            target="_blank"
            rel="noreferrer"
          >
            CMS Login
          </a>
          {/* <p className="itn-header-text">
            Developing, funding and conducting clinical trials in immune
            tolerance
          </p> */}
        </div>
      </div>
    </header>
  );
}

export default Header;
